@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-Medium.ttf') format('opentype');
  font-weight: 400; /* Adjust this as per your font weight */
  font-style: normal;
}

body{
  background-color: #ededed;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-Light.ttf') format('opentype');
  font-weight: 300; /* Light */
  font-style: light;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-Regular.ttf') format('opentype');
  font-weight: 400; /* Book (Regular) */
  font-style: book;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-SemiBold.ttf') format('opentype');
  font-weight: 500; /* Demi */
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-Bold.ttf') format('opentype');
  font-weight: 700; /* Bold */
  font-style: bold;
}

body {
  margin: 0;
  font-family: 'Jost';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-animation {
  position: relative; /* Needed for the transform property */
  transition: transform 0.3s ease; /* Smooth transition for the transform property */
}

.button-animation:hover {
  transform: translateX(-10px); /* Move 5px to the left on hover */
}


video::-webkit-media-controls-volume-slider {
  display: none;
}

video::-webkit-media-controls-mute-button {
  display: none;
}
